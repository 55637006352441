import React, { useState, useRef, useEffect } from 'react';
import { Mic, X, Volume2, VolumeX, Camera, RefreshCw, CameraOff, Loader2 } from 'lucide-react';
import { 
  handleVoiceInteraction, 
  setupCamera, 
  stopImageStreaming, 
  stopRealtimeSession 
} from '../services/voiceopenai';

const VoiceModePopup = ({ 
  isOpen, 
  onClose, 
  darkMode, 
  onMessageReceived,
  audioRef
}) => {
  const [isListening, setIsListening] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [cameraEnabled, setCameraEnabled] = useState(true);
  const [sessionActive, setSessionActive] = useState(false);
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [cameraFacing, setCameraFacing] = useState('user');

  const userVideoRef = useRef(null);
  const cameraStreamRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      // Start the camera feed when popup opens.
      setupCamera(userVideoRef.current, cameraFacing)
        .then(() => setCameraEnabled(true))
        .catch((err) => setError(err));
    } else {
      stopCamera();
      stopImageStreaming();
      stopRealtimeSession();
      setSessionActive(false);
    }
    return () => {
      stopCamera();
      stopImageStreaming();
      stopRealtimeSession();
      setSessionActive(false);
    };
  }, [isOpen, cameraFacing]);

  const stopCamera = () => {
    if (cameraStreamRef.current) {
      cameraStreamRef.current.getTracks().forEach((track) => track.stop());
      cameraStreamRef.current = null;
    }
    if (userVideoRef.current) {
      userVideoRef.current.srcObject = null;
    }
  };

  const startSession = async () => {
    try {
      await handleVoiceInteraction(userVideoRef.current);
      onMessageReceived("Voice session started. Listening for your query...");
      setSessionActive(true);
    } catch (error) {
      console.error('Error in voice interaction:', error);
      setError('An error occurred during the voice interaction. Please try again.');
    }
  };

  const stopSession = () => {
    stopImageStreaming();
    stopRealtimeSession();
    setSessionActive(false);
    onMessageReceived("Voice session stopped.");
  };

  const handleVoiceInteractionToggle = async () => {
    if (sessionActive) {
      // Stop the session
      stopSession();
    } else {
      if (isProcessing) return;
      setIsProcessing(true);
      setIsListening(true);
      await startSession();
      setIsListening(false);
      setIsProcessing(false);
    }
  };

  const toggleAudio = () => {
    setAudioEnabled(!audioEnabled);
  };

  const toggleCamera = async () => {
    if (cameraEnabled) {
      stopCamera();
      setCameraEnabled(false);
    } else {
      try {
        await setupCamera(userVideoRef.current, cameraFacing);
        setCameraEnabled(true);
      } catch (error) {
        console.error('Error accessing camera:', error);
        setError('Failed to access the camera. Please check your permissions and try again.');
      }
    }
  };

  const switchCamera = async () => {
    const newFacing = cameraFacing === 'user' ? 'environment' : 'user';
    setCameraFacing(newFacing);
    if (cameraEnabled) {
      stopCamera();
      try {
        await setupCamera(userVideoRef.current, newFacing);
      } catch (error) {
        console.error('Error switching camera:', error);
        setError('Failed to switch the camera. Please try again.');
      }
    }
  };

  const handleTryAgain = () => {
    setError(null);
    setupCamera(userVideoRef.current, cameraFacing);
  };

  const handleClose = () => {
    stopCamera();
    stopImageStreaming();
    stopRealtimeSession();
    setSessionActive(false);
    onClose();
  };

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50">
      <div className={`${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} rounded-lg shadow-lg p-4 w-full max-w-md mx-4`}>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Speak with Ava</h2>
          <button onClick={handleClose} className="text-gray-500 hover:text-gray-700 transition-colors">
            <X size={24} />
          </button>
        </div>
        {error ? (
          <div className="mt-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
            <p>{error}</p>
            <button 
              onClick={handleTryAgain}
              className="mt-3 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors flex items-center"
            >
              <RefreshCw size={18} className="mr-2" />
              Try Again
            </button>
          </div>
        ) : (
          <>
            <div className="mb-4 relative rounded-lg overflow-hidden shadow-md" style={{ paddingTop: '56.25%' }}>
              <video ref={userVideoRef} className="absolute top-0 left-0 w-full h-full object-cover" autoPlay playsInline muted />
            </div>
            <div className="flex flex-col items-center mb-4">
              <button
                onClick={handleVoiceInteractionToggle}
                disabled={isProcessing}
                className={`py-3 px-4 rounded-full text-lg font-semibold flex items-center justify-center shadow-md transition-all duration-300 ${
                  isProcessing || isListening
                    ? 'bg-blue-500 text-white'
                    : sessionActive
                    ? 'bg-red-500 text-white hover:bg-red-600'
                    : 'bg-blue-500 text-white hover:bg-blue-600'
                }`}
              >
                {(isProcessing || isListening) ? (
                  <span className="flex items-center">
                    <Loader2 size={20} className="animate-spin" />
                    <span className="ml-1">Loading...</span>
                  </span>
                ) : (
                  sessionActive ? 'Stop Speaking' : 'Start Speaking'
                )}
              </button>
            </div>
            <div className="flex flex-col items-center mb-4">
              <div className="flex justify-center w-full">
                <button
                  onClick={toggleAudio}
                  className={`p-3 rounded-full ${audioEnabled ? 'bg-green-500 text-white' : 'bg-red-500 text-white'} hover:opacity-90 transition-all duration-300 shadow-md mr-3`}
                >
                  {audioEnabled ? <Volume2 size={24} /> : <VolumeX size={24} />}
                </button>
                <button
                  onClick={toggleCamera}
                  className={`p-3 rounded-full ${cameraEnabled ? 'bg-green-500 text-white' : 'bg-red-500 text-white'} hover:opacity-90 transition-all duration-300 shadow-md`}
                >
                  {cameraEnabled ? <Camera size={24} /> : <CameraOff size={24} />}
                </button>
                {isMobile && cameraEnabled && (
                  <button
                    onClick={switchCamera}
                    className="p-3 rounded-full bg-blue-500 text-white hover:opacity-90 transition-all duration-300 shadow-md ml-3"
                  >
                    <RefreshCw size={24} />
                  </button>
                )}
              </div>
            </div>
            <div className="flex justify-between text-xs text-gray-500">
              <p>{audioEnabled ? 'Audio on' : 'Audio off'}</p>
              <p>{cameraEnabled ? (isMobile ? (cameraFacing === 'user' ? 'Front camera' : 'Back camera') : 'Camera on') : 'Camera off'}</p>
            </div>
          </>
        )}
        <p className="text-xs text-yellow-500 mt-4 bg-yellow-100 dark:bg-yellow-900 p-2 rounded-lg">
          Disclaimer: This voice mode feature is experimental.
        </p>
      </div>
    </div>
  );
};

export default VoiceModePopup;
